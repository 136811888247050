<template>
  <div>
    <b-card>
      <b-row>

        <!-- User Info: Left col -->
        <b-col
          cols="6"
          md="4"
          class="d-flex justify-content-between flex-column"
        >
          <!-- User Avatar & Action Buttons -->
          <div class="d-flex justify-content-start">
            <b-avatar
              :src="zoneData.avatar"
              :text="avatarText(`${zoneData.prenom} ${zoneData.nom}`)"
              :variant="`light-${resolveUserRoleVariant(zoneData.role)}`"
              size="104px"
              rounded
            />
            <div class="d-flex flex-column ml-1">
              <div class="mb-1">
                <h4 class="mb-0">
                  {{ zoneData.prenom }} {{ zoneData.nom }}
                </h4>
                <span class="card-text">{{ zoneData.telephone }}</span>
              </div>
              <div class="d-flex flex-wrap">
                <b-button
                  :to="{ name: 'apps-users-edit', params: { id: zoneData.id } }"
                  variant="primary"
                >
                  Modifier
                </b-button>
              </div>
            </div>
          </div>

          <!-- User Stats -->
          <div class="d-flex align-items-center mt-2">
            <div class="d-flex align-items-center mr-2">
              <b-avatar
                variant="light-primary"
                rounded
              >
                <feather-icon
                  icon="AwardIcon"
                  size="18"
                />
              </b-avatar>
              <div class="ml-1">
                <h5 class="mb-0">
                  {{ zoneData.score }}
                  1500
                </h5>
                <small>Score</small>
              </div>
            </div>

            <div class="d-flex align-items-center">
              <b-avatar
                variant="light-success"
                rounded
              >
                <feather-icon
                  icon="TrendingUpIcon"
                  size="18"
                />
              </b-avatar>
              <div class="ml-1">
                <h5 class="mb-0">
                  80%
                </h5>
                <small>Taux</small>
              </div>
            </div>
          </div>
        </b-col>

        <!-- Right Col: Table -->
        <b-col
          cols="6"
          md="4"
        >
          <table class="mt-2 mt-xl-0 w-100">
            <tr>
              <th class="pb-50">
                <img
                  width="30px"
                  :src="imageResponsable"
                  alt="Description de l'image"
                >
                <span class="font-weight-bold ml-1">Responsable :</span>
              </th>
              <td class="pb-50">
                {{ zoneData.responsable }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <img
                  width="30px"
                  :src="imageCommerciaux"
                  alt="Description de l'image"
                >
                <span class="font-weight-bold ml-1">Nombre de Commerciaux :</span>
              </th>
              <td class="pb-50 text-capitalize">
                {{ zoneData.nombre_commerciaux }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <img
                  width="30px"
                  :src="imageDistributeurs"
                  alt="Description de l'image"
                >
                <span class="font-weight-bold ml-1">Nombre de Distributeurs : </span>
              </th>
              <td class="pb-50 text-capitalize">
                {{ zoneData.nombre_distributeurs }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <img
                  width="30px"
                  :src="imageTourners"
                  alt="Description de l'image"
                >
                <span class="font-weight-bold ml-1">Nombre de Tournées : </span>
              </th>
              <td class="pb-50 text-capitalize">
                {{ zoneData.nombre_tournees }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <img
                  width="30px"
                  :src="imagePDV"
                  alt="Description de l'image"
                >
                <span class="font-weight-bold ml-1">Nombre de PDV : </span>
              </th>
              <td class="pb-50">
                {{ zoneData.nombre_pdv }}
              </td>
            </tr>
          </table>
        </b-col>
        <b-col
          cols="6"
          md="4"
        >
          <vue-apex-charts
            height="120"
            :options="earningsChart.chartOptions"
            :series="[70, 30]"
          />
          <h4 class="mb-0 text-center">
            Les Tournées du jour
          </h4>
        </b-col>
      </b-row>
    </b-card>
    <b-card>

      <b-tabs
        pills
      >

        <!-- Tab: Account -->
        <b-tab active>
          <template #title>
            <span class="tab-image-icon">
              <img
                width="20px"
                :src="imageCommerciaux"
                alt="Description de l'image"
              >
            </span>

            <span class="d-none d-sm-inline ml-1">Commerciaux</span>
          </template>
          <CommerciauxListeMainVue
            :rows="commerciaux"
            cols=""
          />
        </b-tab>

        <!-- Tab: Information -->
        <b-tab>
          <template #title>
            <span class="tab-image-icon">
              <img
                width="20px"
                :src="imageDistributeurs"
                alt="Description de l'image"
              >
            </span>
            <span class="d-none d-sm-inline ml-1">Distributeurs</span>
          </template>
          <DistributeursListeMainVue :rows="[]" />
        </b-tab>

        <!-- Tab: Social -->
        <b-tab>
          <template #title>
            <span class="tab-image-icon">
              <img
                width="20px"
                :src="imageTourners"
                alt="Description de l'image"
              >
            </span>
            <span class="d-none d-sm-inline ml-1">Tournées - Commerciale</span>
          </template>
          <TournerListeMainVue :rows="[]" />
        </b-tab>
      </b-tabs>
    </b-card>
  </div>

</template>

<script>
import {
  BAvatar, BButton, BCard, BCol, BRow, BTab, BTabs,
} from 'bootstrap-vue'
// eslint-disable-next-line import/no-cycle
import useUsersList from '@/views/apps/user/users-list/useUsersList'
// eslint-disable-next-line import/no-cycle
import { avatarText } from '@core/utils/filter'
import ListeCommerciaux from '@/views/afridia/zones/details/ListeCommerciaux.vue'
import ListeDistributeurs from '@/views/afridia/zones/details/ListeDistributeurs.vue'
import ListeTourners from '@/views/afridia/zones/details/ListeTourners.vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
import CommerciauxListeMainVue from '@/views/afridia/commerciaux/liste/CommerciauxListeMainVue.vue'
import DistributeursListeMainVue from '@/views/afridia/distributeurs/liste/DistributeursListeMainVue.vue'
import TournerListeMainVue from '@/views/afridia/tourners/liste/TournerListeMainVue.vue'

const $earningsStrokeColor2 = 'rgb(3,147,255)'
const $earningsStrokeColor3 = 'rgba(3,147,255,0.28)'

export default {
  name: 'MainVue',
  components: {
    TournerListeMainVue,
    DistributeursListeMainVue,
    CommerciauxListeMainVue,
    ListeTourners,
    ListeDistributeurs,
    BTabs,
    BTab,
    BButton,
    BAvatar,
    BRow,
    BCol,
    BCard,
    VueApexCharts,
  },
  data() {
    return {
      earningsChart: {
        chartOptions: {
          chart: {
            type: 'donut',
            toolbar: {
              show: false,
            },
          },
          dataLabels: {
            enabled: true,
          },
          legend: { show: true },
          comparedResult: [47, 53],
          labels: ['Effectuées', 'Restants'],
          stroke: { width: 0 },
          colors: [$earningsStrokeColor2, $earningsStrokeColor3, $themeColors.success],
          grid: {
            padding: {
              right: -20,
              bottom: -8,
              left: -20,
            },
          },
          plotOptions: {
            pie: {
              startAngle: 0,
              donut: {
                labels: {
                  show: true,
                  name: {
                    offsetY: 15,
                  },
                  value: {
                    offsetY: -15,
                    formatter(val) {
                      // eslint-disable-next-line radix
                      return `${parseInt(val)}%`
                    },
                  },
                  total: {
                    show: true,
                    offsetY: 15,
                    label: 'Tournées',
                    formatter() {
                      return '70%'
                    },
                  },
                },
              },
            },
          },
          responsive: [
            {
              breakpoint: 1325,
              options: {
                chart: {
                  height: 100,
                },
              },
            },
            {
              breakpoint: 1200,
              options: {
                chart: {
                  height: 120,
                },
              },
            },
            {
              breakpoint: 1045,
              options: {
                chart: {
                  height: 100,
                },
              },
            },
            {
              breakpoint: 992,
              options: {
                chart: {
                  height: 120,
                },
              },
            },
          ],
        },
      },
      zoneData: {
        id: 1,
        nom: 'Zone Est',
        responsable: 'M. KOUADIO',
        nombre_commerciaux: 2,
        nombre_distributeurs: 3,
        nombre_tournees: 4,
        nombre_pdv: 5,
      },
      commerciaux: [
        {
          id: 1,
          nom: 'Ouedraogo',
          prenom: 'Fatimata',
          telephone: '01 23 45 67',
          responsable: 'Directeur régional',
          canal: 'Canal moderne',
          nbr_distributeurs: 10,
          nbr_tourners: 15,
          nbr_pdv: 50,
          status: 'Actif',
          action: '',
        },
        {
          id: 2,
          nom: 'Sanou',
          prenom: 'Boubacar',
          telephone: '01 23 45 68',
          responsable: 'Responsable des ventes',
          canal: 'Canal traditionnel',
          nbr_distributeurs: 20,
          nbr_tourners: 12,
          nbr_pdv: 80,
          status: 'Inactif',
          action: '',
        },
        {
          id: 3,
          nom: 'Zongo',
          prenom: 'Mariam',
          telephone: '01 23 45 69',
          responsable: 'Responsable de la logistique',
          canal: 'Canal moderne',
          nbr_distributeurs: 15,
          nbr_tourners: 18,
          nbr_pdv: 60,
          status: 'Actif',
          action: '',
        },
      ],
      // eslint-disable-next-line global-require
      imageDistributeurs: require('@/assets/images/icons/box.png'),
      // eslint-disable-next-line global-require
      imageCommerciaux: require('@/assets/images/icons/team.png'),
      // eslint-disable-next-line global-require
      imageResponsable: require('@/assets/images/icons/user-gear.png'),
      // eslint-disable-next-line global-require
      imageTourners: require('@/assets/images/icons/tourners.png'),
      // eslint-disable-next-line global-require
      imagePDV: require('@/assets/images/icons/store.png'),
      userData: {},
    }
  },
  setup() {
    const { resolveUserRoleVariant } = useUsersList()
    return {
      avatarText,
      resolveUserRoleVariant,
    }
  },
}
</script>

<style scoped>

</style>
